import http from "../../http-common";
/* eslint-disable */
class BeritaService {
  beritaList(page, itemPerPage, keyword) {
    return http.get(
      "/article/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getBerita(id) {
    return http.get(`/article/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, foto) {
    var bodyFormData = new FormData();
    bodyFormData.append("title", data.judul);
    bodyFormData.append("subtitle", data.subtitle);
    if (data.content)
      bodyFormData.append("content", JSON.stringify(data.content));
    if (data.content_html)
      bodyFormData.append("content_html", data.content_html);

    if (data.selectedHide && data.selectedHide != undefined) {
      bodyFormData.append("article_category_id", data.selectedHide.text);
    }

    if (foto != null) bodyFormData.append("photo", foto);

    return http.post(`/article`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data, foto) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("title", data.judul);
    bodyFormData.append("subtitle", data.subtitle);
    if (data.content)
      bodyFormData.append("content", JSON.stringify(data.content));
    if (data.content_html)
      bodyFormData.append("content_html", data.content_html);

    if (data.selectedHide && data.selectedHide != undefined) {
      bodyFormData.append("article_category_id", data.selectedHide.id);
    }

    if (foto != null) bodyFormData.append("photo", foto);

    return http.put(`/article/${data.id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/berita/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new BeritaService();
