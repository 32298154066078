<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-tabs>
          <v-tab>Artikel</v-tab>
          <v-tab-item>
            <v-card>
              <v-card-title> Edit Artikel </v-card-title>
              <v-spacer />
              <v-fab-transition>
                <v-btn
                  class="mt-8 mr-4"
                  v-if="isEditable == 0"
                  @click="changeEditable"
                  color="pink"
                  dark
                  absolute
                  top
                  right
                  fab
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-btn color="purple" dark class="mb-2" @click="tambahFoto">
                Tambah
              </v-btn>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4 pt-4"
              >
                <v-row dense>
                  <v-col cols="12" sm="6" md="6" class="pr-4">
                    <v-sheet color="white" elevation="2">
                      <v-responsive :aspect-ratio="16 / 9">
                        <v-img
                          :key="fotoKey"
                          :src="userData.FotoUrl"
                          contain
                        ></v-img>
                      </v-responsive>
                    </v-sheet>
                    <v-file-input
                      @change="loadImage"
                      accept="image/*"
                      v-model="userData.file_foto"
                      prepend-icon="mdi-image"
                      show-size
                      label="Foto"
                      :disabled="isEditable == 0"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          label="Judul"
                          v-model="userData.judul"
                          :rules="userData.namaRules"
                          rows="6"
                          row-height="15"
                          :readonly="isEditable == 0"
                        ></v-textarea>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          outlined
                          label="Subtitle"
                          v-model="userData.subtitle"
                          rows="6"
                          row-height="15"
                          :readonly="isEditable == 0"
                        ></v-textarea>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12" md="12">
                        <v-combobox
                          label="Kategori"
                          clearable
                          dense
                          outlined
                          v-model="userData.selectedHide"
                          :items="items_category"
                          item-text="name"
                          :readonly="isEditable == 0"
                          :rules="userData.hideRules"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h5>Konten Berita</h5>
                  </v-col>
                </v-row>
                <v-row v-if="editor">
                  <v-col cols="12" sm="10" md="10" class="">
                    <!-- <vue-editor
                      id="editor"
                      useCustomImageHandler
                      @image-added="handleImageAdded"
                      v-model="userData.content"
                      :disabled="isEditable == 0"
                    /> -->
                    <button
                      class="button_editor"
                      type="button"
                      @click="editor.chain().focus().undo().run()"
                      :disabled="!editor.can().undo()"
                    >
                      undo
                    </button>
                    <button
                      class="button_editor"
                      type="button"
                      @click="editor.chain().focus().redo().run()"
                      :disabled="!editor.can().redo()"
                    >
                      redo
                    </button>

                    <button
                      class="button_editor"
                      type="button"
                      @click="
                        editor.chain().focus().toggleHeading({ level: 1 }).run()
                      "
                      :class="{
                        'is-active': editor.isActive('heading', { level: 1 }),
                      }"
                    >
                      H1
                    </button>
                    <button
                      class="button_editor"
                      type="button"
                      @click="
                        editor.chain().focus().toggleHeading({ level: 2 }).run()
                      "
                      :class="{
                        'is-active': editor.isActive('heading', { level: 2 }),
                      }"
                    >
                      H2
                    </button>
                    <button
                      class="button_editor"
                      type="button"
                      @click="
                        editor.chain().focus().toggleHeading({ level: 3 }).run()
                      "
                      :class="{
                        'is-active': editor.isActive('heading', { level: 3 }),
                      }"
                    >
                      H3
                    </button>

                    <button
                      class="button_editor"
                      type="button"
                      @click="editor.chain().focus().setTextAlign('left').run()"
                      :class="{
                        'is-active': editor.isActive({ textAlign: 'left' }),
                      }"
                    >
                      left
                    </button>
                    <button
                      class="button_editor"
                      type="button"
                      @click="
                        editor.chain().focus().setTextAlign('center').run()
                      "
                      :class="{
                        'is-active': editor.isActive({ textAlign: 'center' }),
                      }"
                    >
                      center
                    </button>
                    <button
                      class="button_editor"
                      type="button"
                      @click="
                        editor.chain().focus().setTextAlign('right').run()
                      "
                      :class="{
                        'is-active': editor.isActive({ textAlign: 'right' }),
                      }"
                    >
                      right
                    </button>
                    <button
                      class="button_editor"
                      type="button"
                      @click="
                        editor.chain().focus().setTextAlign('justify').run()
                      "
                      :class="{
                        'is-active': editor.isActive({ textAlign: 'justify' }),
                      }"
                    >
                      justify
                    </button>
                    <button
                      class="button_editor"
                      type="button"
                      @click="editor.chain().focus().toggleBlockquote().run()"
                      :class="{ 'is-active': editor.isActive('blockquote') }"
                    >
                      toggleBlockquote
                    </button>
                    <button
                      class="button_editor"
                      type="button"
                      @click="editor.chain().focus().toggleCodeBlock().run()"
                      :class="{ 'is-active': editor.isActive('codeBlock') }"
                    >
                      toggleCodeBlock
                    </button>
                    <button
                      class="button_editor"
                      type="button"
                      @click="editor.chain().focus().setHardBreak().run()"
                    >
                      setHardBreak
                    </button>
                    <button
                      class="button_editor"
                      type="button"
                      @click="editor.chain().focus().setHorizontalRule().run()"
                    >
                      setHorizontalRule
                    </button>
                    <button
                      class="button_editor"
                      type="button"
                      @click="addImage"
                    >
                      setImage
                    </button>

                    <button
                      class="button_editor"
                      type="button"
                      @click="editor.chain().focus().toggleTaskList().run()"
                      :class="{ 'is-active': editor.isActive('taskList') }"
                    >
                      toggleTaskList
                    </button>

                    <button
                      class="button_editor"
                      type="button"
                      id="add"
                      @click="addVideo"
                    >
                      Add YouTube video
                    </button>
                    <input
                      id="width"
                      type="number"
                      v-model="width"
                      placeholder="width"
                      min="320"
                      max="1024"
                    />
                    <input
                      id="height"
                      type="number"
                      v-model="height"
                      placeholder="height"
                      min="180"
                      max="720"
                    />

                    <div class="row" style="margin-top: 3px; margin-left: 8px">
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().toggleBold().run()"
                        :class="{ 'is-active': editor.isActive('bold') }"
                      >
                        <b>B</b>
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().toggleItalic().run()"
                        :class="{ 'is-active': editor.isActive('italic') }"
                      >
                        <i>I</i>
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().toggleStrike().run()"
                        :class="{ 'is-active': editor.isActive('strike') }"
                      >
                        toggleStrike
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().toggleUnderline().run()"
                        :class="{ 'is-active': editor.isActive('underline') }"
                      >
                        toggleUnderline
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="setLink"
                        :class="{ 'is-active': editor.isActive('link') }"
                      >
                        setLink
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().unsetLink().run()"
                        :disabled="!editor.isActive('link')"
                      >
                        unsetLink
                      </button>
                    </div>

                    <div class="row" style="margin-top: 12px; margin-left: 8px">
                      <input
                        type="color"
                        @input="
                          editor
                            .chain()
                            .focus()
                            .setColor($event.target.value)
                            .run()
                        "
                        :value="editor.getAttributes('textStyle').color"
                      />
                      <button
                        class="button_editor"
                        type="button"
                        @click="
                          editor.chain().focus().setColor('#958DF1').run()
                        "
                        :class="{
                          'is-active': editor.isActive('textStyle', {
                            color: '#958DF1',
                          }),
                        }"
                      >
                        purple
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="
                          editor.chain().focus().setColor('#F98181').run()
                        "
                        :class="{
                          'is-active': editor.isActive('textStyle', {
                            color: '#F98181',
                          }),
                        }"
                      >
                        red
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="
                          editor.chain().focus().setColor('#FBBC88').run()
                        "
                        :class="{
                          'is-active': editor.isActive('textStyle', {
                            color: '#FBBC88',
                          }),
                        }"
                      >
                        orange
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="
                          editor.chain().focus().setColor('#FAF594').run()
                        "
                        :class="{
                          'is-active': editor.isActive('textStyle', {
                            color: '#FAF594',
                          }),
                        }"
                      >
                        yellow
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="
                          editor.chain().focus().setColor('#70CFF8').run()
                        "
                        :class="{
                          'is-active': editor.isActive('textStyle', {
                            color: '#70CFF8',
                          }),
                        }"
                      >
                        blue
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="
                          editor.chain().focus().setColor('#94FADB').run()
                        "
                        :class="{
                          'is-active': editor.isActive('textStyle', {
                            color: '#94FADB',
                          }),
                        }"
                      >
                        teal
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="
                          editor.chain().focus().setColor('#B9F18D').run()
                        "
                        :class="{
                          'is-active': editor.isActive('textStyle', {
                            color: '#B9F18D',
                          }),
                        }"
                      >
                        green
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().unsetColor().run()"
                      >
                        unsetColor
                      </button>
                    </div>

                    <div class="row">
                      <button
                        class="button_editor"
                        type="button"
                        @click="
                          editor
                            .chain()
                            .focus()
                            .insertTable({
                              rows: 3,
                              cols: 3,
                              withHeaderRow: true,
                            })
                            .run()
                        "
                      >
                        insertTable
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().addColumnBefore().run()"
                      >
                        addColumnBefore
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().addColumnAfter().run()"
                      >
                        addColumnAfter
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().deleteColumn().run()"
                      >
                        deleteColumn
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().addRowBefore().run()"
                      >
                        addRowBefore
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().addRowAfter().run()"
                      >
                        addRowAfter
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().deleteRow().run()"
                      >
                        deleteRow
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().deleteTable().run()"
                      >
                        deleteTable
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().mergeCells().run()"
                      >
                        mergeCells
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().splitCell().run()"
                      >
                        splitCell
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="
                          editor.chain().focus().toggleHeaderColumn().run()
                        "
                      >
                        toggleHeaderColumn
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().toggleHeaderRow().run()"
                      >
                        toggleHeaderRow
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().toggleHeaderCell().run()"
                      >
                        toggleHeaderCell
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().mergeOrSplit().run()"
                      >
                        mergeOrSplit
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="
                          editor
                            .chain()
                            .focus()
                            .setCellAttribute('colspan', 2)
                            .run()
                        "
                      >
                        setCellAttribute
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().fixTables().run()"
                      >
                        fixTables
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().goToNextCell().run()"
                      >
                        goToNextCell
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="editor.chain().focus().goToPreviousCell().run()"
                      >
                        goToPreviousCell
                      </button>
                      <button
                        class="button_editor"
                        type="button"
                        @click="
                          editor.chain().focus().toggleOrderedList().run()
                        "
                      >
                        Ordered List
                      </button>
                    </div>

                    <editor-content style="margin-top: 20px" :editor="editor" />

                    <div class="character-count" v-if="editor">
                      {{ editor.storage.characterCount.characters() }}/{{
                        limitEditor
                      }}
                      characters
                      <br />
                      {{ editor.storage.characterCount.words() }} words
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12"> </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn
                      v-if="isEditable == 1"
                      @click="doBatal"
                      style="margin-right: 10px"
                      >Batal</v-btn
                    >
                    <v-btn
                      v-if="isEditable == 1"
                      dark
                      color="primary"
                      type="button"
                      @click="doSubmit"
                      >Simpan</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-container>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Upload Foto</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  accept="image/*"
                  v-model="file_upload"
                  prepend-icon="mdi-image"
                  show-size
                  label="Foto"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import BeritaService from "../../services/web_content/BeritaService";
import ArticleCategoryService from "../../services/ArticleCategoryService";
import CommonService from "../../services/CommonService";
import EventService from "../../services/EventService";
import { uuid } from "vue-uuid";

import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import CharacterCount from "@tiptap/extension-character-count";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Image from "@tiptap/extension-image";
import TextAlign from "@tiptap/extension-text-align";
import Typography from "@tiptap/extension-typography";

import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";

import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";

import Youtube from "@tiptap/extension-youtube";

import Link from "@tiptap/extension-link";

import Underline from "@tiptap/extension-underline";

// import { generateHTML, generateJSON } from "@tiptap/html";
//bubble menu not working

export default {
  name: "BeritaEdit",
  components: { EditorContent },
  props: {
    id: String,
  },
  data: () => ({
    file_upload: null,
    dialog: false,
    editor: null,
    limitEditor: 2000,
    width: "640",
    height: "480",
    komunitas_key: 0,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Artikel",
        disabled: false,
        exact: true,
        to: { name: "Berita" },
      },
      { text: "Edit Artikel", disabled: true },
    ],
    valid: true,
    userData: {
      FotoUrl: "",
      file_foto: null,
      judul: "",
      subtitle: "",
      content: "",
      content_html: "",
      selectedHide: "",
      namaRules: [(v) => !!v || "Judul harus diisi"],
      hideRules: [(v) => !!v || "Is Hide harus diisi"],
    },
    items_category: [],
    result: {},
    fotoKey: 0,
    searchNakes: "",
  }),
  mounted: async function () {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        CharacterCount,
        TextStyle,
        Color,
        Image,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        Typography,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,

        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        Youtube.configure({
          controls: false,
        }),
        Link.configure({
          openOnClick: false,
        }),
        Underline,
      ],
      content: ``,
    });

    this.getArticleCategories();
    this.getBerita();
  },
  computed: {
    imageSrc() {
      return this.userData.FotoUrl;
    },
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  watch: {},
  methods: {
    tambahFoto: function () {
      this.dialog = true;
    },
    changeEditable: function () {
      this.isEditable = 1;
    },

    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.userData.FotoUrl = "";
        this.userData.file_foto = null;
      }
    },

    loadImageUpload: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userData.FotoUrl = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.userData.FotoUrl = "";
        this.userData.file_foto = null;
      }
    },

    getBerita() {
      BeritaService.getBerita(this.id)
        .then((res) => {
          console.log(res.data.user);
          this.userData.judul = res.data.article.title;
          this.userData.subtitle = res.data.article.subtitle;

          if (res.data.article.content_html) {
            console.log("render html");
            this.editor.commands.setContent(res.data.article.content_html);
          } else {
            console.log("render json");
            console.log(res.data.article.content);
            this.editor.commands.setContent(
              JSON.parse(res.data.article.content)
            );
          }

          this.userData.FotoUrl = res.data.article.image;
          this.fotoKey++;

          this.items_category.forEach((entry) => {
            if (entry.id == res.data.article.article_category_id) {
              this.userData.selectedHide = entry;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getArticleCategories() {
      ArticleCategoryService.getArticleCategoryList()
        .then((res) => {
          this.items_category = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go();
    },

    doSubmit: async function () {
      console.log(this.editor.getJSON());
      console.log(this.editor.getHTML());

      this.userData.content = this.editor.getJSON();
      this.userData.content_html = this.editor.getHTML();
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      BeritaService.update(this.id, this.userData, this.userData.file_foto)
        .then((res) => {
          console.log(res);
          this.isEditable = 0;

          CommonService.showSuccessToast("Update artikel berhasil");
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Update artikel gagal. Coba lagi");
        });
    },

    doSave: async function () {
      EventService.uploadFotoKonten(uuid.v4(), this.file_upload, 1)
        .then((res) => {
          console.log(res);

          this.dialog = false;
          alert(res.data.article.foto_url);
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Update artikel gagal. Coba lagi");
        });
    },

    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      //1 = kategori event
      EventService.uploadFotoKonten(uuid.v4(), file, 1)
        .then((res) => {
          console.log(res);

          Editor.insertEmbed(
            cursorLocation,
            "image",
            res.data.article.foto_url
          );
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showSuccessToast("Gagal menambah foto. Coba lagi");
        });
    },

    addImage() {
      const url = window.prompt("URL");

      if (url) {
        this.editor.chain().focus().setImage({ src: url }).run();
      }
    },

    addVideo() {
      const url = prompt("Enter YouTube URL");

      this.editor.commands.setYoutubeVideo({
        src: url,
        width: Math.max(320, parseInt(this.width, 10)) || 640,
        height: Math.max(180, parseInt(this.height, 10)) || 480,
      });
    },

    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
  },
};
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  padding: 16px;

  hr.ProseMirror-selectednode {
    border-top: 1px solid #68cef8;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: white;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  blockquote {
    padding-left: 1rem;
    border-left: 6px solid rgba(#0d0d0d, 0.1);
  }

  img {
    max-width: 100%;
    height: auto;

    &.ProseMirror-selectednode {
      outline: 3px solid #68cef8;
    }
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }

  iframe {
    border: 8px solid #000;
    border-radius: 4px;
    min-width: 200px;
    min-height: 200px;
    display: block;
    outline: 0px solid transparent;
  }

  div[data-youtube-video] {
    cursor: move;
    padding-right: 24px;
  }

  .ProseMirror-selectednode iframe {
    transition: outline 0.15s;
    outline: 6px solid #ece111;
  }

  a {
    color: #68cef8;
  }

  code {
    font-size: 0.9rem;
    padding: 0.25em;
    border-radius: 0.25em;
    background-color: rgba(#616161, 0.1);
    color: #616161;
    box-decoration-break: clone;
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

ul[data-type="taskList"] {
  list-style: none;
  padding: 0;

  p {
    margin: 0;
  }

  li {
    display: flex;

    > label {
      flex: 0 0 auto;
      margin-right: 0.5rem;
      user-select: none;
    }

    > div {
      flex: 1 1 auto;
    }
  }
}

.button_editor {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 3px 14px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 5px;
  border-radius: 8px;
  margin-top: 5px;
}
</style>
