import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import Login from "../views/Login.vue";

//web content
import Berita from "../views/webcontent/BeritaList.vue";
import BeritaAdd from "../views/webcontent/BeritaAdd.vue";
import BeritaEdit from "../views/webcontent/BeritaEdit.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    props: true,
    component: Login,
    meta: {
      isHome: 0,
    },
  },
  {
    path: "/webcontent/berita",
    name: "BeritaList",
    props: true,
    component: Berita,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/berita-add",
    name: "BeritaAdd",
    props: true,
    component: BeritaAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/berita-edit/:id",
    name: "BeritaEdit",
    props: true,
    component: BeritaEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

export default router;
