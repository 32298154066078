import http from "../http-common";
/* eslint-disable */
class ArticleCategoryService {
  getArticleCategoryList() {
    return http.get("/article-category/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new ArticleCategoryService();
