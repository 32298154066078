import http from "../http-common";
/* eslint-disable */
class EventService {
  uploadFotoKonten(uuid, photo, kategori) {
    var bodyFormData = new FormData();
    if (uuid) bodyFormData.append("uuid", uuid);

    if (photo != null) bodyFormData.append("photo", photo);

    return http.post("/article/foto-konten", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new EventService();
