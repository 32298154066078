<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app v-if="$route.meta.isHome === 1">
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-avatar color="primary" size="56">
              <v-img :src="currentUser.photo"></v-img>
            </v-avatar>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ currentUser.nama }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item to="/webcontent/berita" link>
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Artikel</v-list-item-title>
        </v-list-item>

        <v-list-item to="/" link @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="primary" dark app dense v-if="$route.meta.isHome === 1">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Admin</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-badge color="green" dot>
        <v-icon medium> mdi-vuetify </v-icon>
      </v-badge>
    </v-app-bar>

    <router-view></router-view>
  </v-app>
</template>

<script>
import store from "./store/index";

export default {
  data: () => ({
    drawer: null,
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    cruds: [
      ["Create", "mdi-plus-outline"],
      ["Read", "mdi-file-outline"],
      ["Update", "mdi-update"],
      ["Delete", "mdi-delete"],
    ],
    currentUser: {
      nama: "",
      photo: "",
      email: "",
    },
    isVerified: 0,
  }),

  mounted() {
    var myUser = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_USER_PREFIX)
    );

    if (myUser) {
      this.currentUser = myUser;
    }

    this.isVerified = store.getters.getUser.is_verified;
  },

  methods: {
    logout: async function () {
      this.$store.dispatch("logout").then(() =>
        this.$router.push("/").catch((err) => {
          console.log(err);
        })
      );
    },
  },

  computed: {
    getRoleId() {
      return store.getters.getUser.role_id;
    },
    getIsVerified() {
      return this.isVerified;
    },
    getUser() {
      return store.getters.getUser;
    },
  },
};
</script>
